<script>
import {
  ArrowUpIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  BellIcon,
  BellOffIcon,
} from "vue-feather-icons";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
/**
 * Page-recruiter component
 */
export default {
  data() {
    return {
      search_text: "",
      selected_cities: [],
      auth: localStorage.getItem("id_token") !== null ? true : false,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      token:
        localStorage.getItem("token") !== null
          ? `Bearer ${localStorage.getItem("token")}`
          : null,
      snackbar: false,
      similarJobs: [],
      industry: null,
      skills: null,
      location_city: null,
      featureCompaniesData: [],
      topCompaniesData: [],
    };
  },
  components: {
    Switcher,
    Footer,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    ArrowUpIcon,
    BellIcon,
    BellOffIcon,
  },
  mounted() {
    this.getFeatureCompanies();
    this.getTopCompanies();
  },
  methods: {
    onLogout() {
      localStorage.clear();
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    getFeatureCompanies() {
      if (this.token !== null) {
        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .get(`${this.apiUrl}/get-feature-companies-auth`)
          .then((response) => {
            this.featureCompaniesData = response.data;
          });
      } else {
        axios.get(`${this.apiUrl}/get-feature-companies`).then((response) => {
          this.featureCompaniesData = response.data;
        });
      }
    },
    getTopCompanies() {
      axios.get(`${this.apiUrl}/get-top-employers`).then((response) => {
        this.topCompaniesData = response.data;
      });
    },
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },
  },
};
</script>

<template>
  <div>
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container">
        <!-- Logo container-->
        <div>
          <a class="logo" href="/" v-if="navLight !== true">
            <img src="/images/logo-dark.svg" height="50" alt="" />
          </a>
          <a class="logo" href="/" v-else>
            <img
              src="/images/logo-dark.svg"
              class="l-dark"
              height="50"
              alt=""
            />
            <img
              src="images/logo-dark.svg"
              class="l-light"
              height="24"
              alt=""
            />
          </a>
        </div>
        <div class="buy-button" style="width: 28% text-align: end;">
          <a
            href="/login"
            class="btn auth_btn_ch1"
            :class="{
              'btn-light': navLight === true,
              'btn-primary': navLight !== true,
            }"
            >Login</a
          >
          <a
            href="/recruiter-registeration"
            class="btn auth_btn_ch2"
            :class="{
              'btn-light': navLight === true,
              'btn-warning': navLight !== true,
            }"
            style="margin-left: 3%"
            >Register</a
          >
          <router-link
            class="btn auth_btn_ch4"
            to="/"
            style="margin-left: 3%; background: #a9250f; color: white"
          >
            <!-- <img src="/images/logo-dark.svg" height="50" alt="" /> -->Job
            Seeker&nbsp;&nbsp;<i
              class="fa fa-caret-right"
              style="color: white"
            ></i>
          </router-link>
          <!-- <a
            href="/dashboard"
            class="btn auth_btn_ch4"
            style="margin-left: 3%; background: #a9250f; color: white"
            >Job Seeker</a
          > -->
        </div>

        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
              class="navbar-toggle"
              @click="toggleMenu()"
              :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul
            class="navigation-menu"
            :class="{ 'nav-light': navLight === true }"
          >
            <li>
              <router-link to="/" class="side-nav-link-ref">Home</router-link>
            </li>
            <li>
              <router-link to="/jobs-in-pakistan" class="side-nav-link-ref"
                >Jobs</router-link
              >
            </li>
            <li>
              <router-link to="/career-resources" class="side-nav-link-ref"
                >Career Resources</router-link
              >
            </li>
          </ul>
          <!--end navigation menu-->
          <div class="buy-menu-btn d-none">
            <a href="#" class="btn btn-primary">Buy Now</a>
          </div>
          <!--end login button-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!-- Hero Start -->
    <section
      class="bg-half bg-light d-table w-100"
      style="padding-top: 100px; padding-bottom: 100px; margin-top: 3%"
      :style="'background: url(http://historic.job4u.pk/htmlcss/images/banner-bg-2.jpg) center center'"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h1 class="title mt-4 mb-3" style="color: white; font-size: 54px">
                Pakistan’s leading job website
              </h1>
              <h1
                class=""
                style="color: white; font-size: 54px; letter-spacing: -1px"
              >
                to help you find right candidate
              </h1>
              <div class="row" style="justify-content: center">
                <div
                  class="buy-button"
                  style="
                    width: 26%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 3%;
                  "
                >
                  <a
                    href="/auth"
                    class="btn btn-default btn-lg outline-btn-inverse"
                    >Learn More</a
                  ><a
                    href="/"
                    class="btn btn-default btn-lg top-form-btn Upload_Your_CV"
                    style="border-color: #005696"
                    >Post a Job</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h1 class="heading_featured align-center">ATTRACT THE BEST JOB SEEKERS</h1>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 row">
          <div class="col-sm-3">
              <div class="thumbnailch">
                  <img src="/images/user-1.jpg" alt="Lights" style="width:100%">
                  <div class="captionch align-center">
                      <h4 class="text-primary">John Doe</h4>
                      <p>Front End Developer</p>
                  </div>
              </div>
          </div>

          <div class="col-sm-3">
              <div class="thumbnailch">
                  <img src="/images/user-2.jpg" alt="Lights" style="width:100%">
                  <div class="captionch align-center">
                      <h4 class="text-primary">Sean William</h4>
                      <p>Java Developer</p>
                  </div>
              </div>
          </div>

          <div class="col-sm-3">
              <div class="thumbnailch">
                  <img src="/images/user-3.jpg" alt="Lights" style="width:100%">
                  <div class="captionch align-center">
                      <h4 class="text-primary">Jane Roe</h4>
                      <p>Creative Associate</p>
                  </div>
              </div>
          </div>

          <div class="col-sm-3">
              <div class="thumbnailch">
                  <img src="/images/user-4.jpg" alt="Lights" style="width:100%">
                  <div class="captionch align-center">
                      <h4 class="text-primary">Richard Miles</h4>
                      <p>PHP Developer</p>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-sm-12 mt-8">
          <h1 class="align-center" style="font-weight: 600;">Looking forward to hire some one ? </h1>
          <br>
          <div class="align-center">
              <a href="#" class="btn btn-default btn-lg top-form-btn Upload_Your_CV" style="border-color: #005696;"><span>Find Out More</span></a>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h1 class="heading_featured align-center">Featured companies</h1>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row">
        <div
          class="col-lg-3 col-md-6 mt-4 pt-2"
          v-for="(item, index) in featureCompaniesData"
          :key="index"
        >
          <div class="card team text-center border-0">
            <div class="card-body job_tiles_ch">
              <div class="position-relative">
                <img
                  :src="mediaUrl + item.profile_pic"
                  class="img-fluid avatar avatar-ex-large rounded-circle shadow"
                  alt=""
                />
              </div>
              <div class="content pt-3 pb-3">
                <h5 class="mb-0">
                  <!-- <a href="javascript:void(0)" class="name text-dark">{{
                      item.company_name
                    }}</a> -->

                  <router-link
                    :to="
                      '/employer/' +
                      item.company_name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')
                    "
                    class="text-dark company-name"
                    >{{ item && item.company_name }}</router-link
                  >
                </h5>
                <small class="designation text-muted">{{
                  item.branches[0].branchAddress
                }}</small>
                <ul class="list-unstyled social-icon team-icon mb-0">
                  <li
                    class="list-inline-item"
                    v-if="item.facebook && item.facebook.length > 0"
                  >
                    <a target="_blank" :href="item.facebook" class="rounded">
                      <facebook-icon
                        class="fea icon-sm fea-social"
                      ></facebook-icon>
                    </a>
                  </li>
                  <li
                    class="list-inline-item ml-1"
                    v-if="item.instagram && item.instagram.length > 0"
                  >
                    <a target="_blank" :href="item.instagram" class="rounded">
                      <instagram-icon
                        class="fea icon-sm fea-social"
                      ></instagram-icon>
                    </a>
                  </li>
                  <li
                    class="list-inline-item ml-1"
                    v-if="item.twitter && item.twitter.length > 0"
                  >
                    <a target="_blank" :href="item.twitter" class="rounded">
                      <twitter-icon
                        class="fea icon-sm fea-social"
                      ></twitter-icon>
                    </a>
                  </li>
                  <li
                    class="list-inline-item ml-1"
                    v-if="item.linkedin && item.linkedin.length > 0"
                  >
                    <a target="_blank" :href="item.linkedin" class="rounded">
                      <linkedin-icon
                        class="fea icon-sm fea-social"
                      ></linkedin-icon>
                    </a>
                  </li>

                  <li class="list-inline-item ml-1" v-if="item.subscribe == 1">
                    <a
                      @click.prevent="subscribe(item)"
                      class="rounded"
                      style="cursor: pointer"
                    >
                      <bell-off-icon
                        class="fea icon-sm fea-social"
                      ></bell-off-icon>
                    </a>
                  </li>
                  <li class="list-inline-item ml-1" v-if="item.subscribe == 0">
                    <a
                      @click.prevent="subscribe(item)"
                      class="rounded"
                      style="cursor: pointer"
                    >
                      <bell-icon class="fea icon-sm fea-social"></bell-icon>
                    </a>
                  </li>
                </ul>
                <!--end icon-->
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h1 class="heading_featured align-center">Top companies</h1>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row">
        <div
          class="col-lg-3 col-md-6 mt-4 pt-2"
          v-for="(item, index) in topCompaniesData"
          :key="index"
        >
          <div class="card team text-center border-0">
            <div class="card-body job_tiles_ch">
              <div class="position-relative">
                <img
                  :src="mediaUrl + item.profile_pic"
                  class="img-fluid avatar avatar-ex-large rounded-circle shadow"
                  alt=""
                />
              </div>
              <div class="content pt-3 pb-3">
                <h5 class="mb-0">
                  <!-- <a href="javascript:void(0)" class="name text-dark">{{
                      item.company_name
                    }}</a> -->

                  <router-link
                    :to="
                      '/employer/' +
                      item.company_name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')
                    "
                    class="text-dark company-name"
                    >{{ item && item.company_name }}</router-link
                  >
                </h5>
                <small class="designation text-muted">{{
                  item.branches[0].branchAddress
                }}</small>
                <ul class="list-unstyled social-icon team-icon mb-0">
                  <li
                    class="list-inline-item"
                    v-if="item.facebook && item.facebook.length > 0"
                  >
                    <a target="_blank" :href="item.facebook" class="rounded">
                      <facebook-icon
                        class="fea icon-sm fea-social"
                      ></facebook-icon>
                    </a>
                  </li>
                  <li
                    class="list-inline-item ml-1"
                    v-if="item.instagram && item.instagram.length > 0"
                  >
                    <a target="_blank" :href="item.instagram" class="rounded">
                      <instagram-icon
                        class="fea icon-sm fea-social"
                      ></instagram-icon>
                    </a>
                  </li>
                  <li
                    class="list-inline-item ml-1"
                    v-if="item.twitter && item.twitter.length > 0"
                  >
                    <a target="_blank" :href="item.twitter" class="rounded">
                      <twitter-icon
                        class="fea icon-sm fea-social"
                      ></twitter-icon>
                    </a>
                  </li>
                  <li
                    class="list-inline-item ml-1"
                    v-if="item.linkedin && item.linkedin.length > 0"
                  >
                    <a target="_blank" :href="item.linkedin" class="rounded">
                      <linkedin-icon
                        class="fea icon-sm fea-social"
                      ></linkedin-icon>
                    </a>
                  </li>

                  <li class="list-inline-item ml-1" v-if="item.subscribe == 1">
                    <a
                      @click.prevent="subscribe(item)"
                      class="rounded"
                      style="cursor: pointer"
                    >
                      <bell-off-icon
                        class="fea icon-sm fea-social"
                      ></bell-off-icon>
                    </a>
                  </li>
                  <li class="list-inline-item ml-1" v-if="item.subscribe == 0">
                    <a
                      @click.prevent="subscribe(item)"
                      class="rounded"
                      style="cursor: pointer"
                    >
                      <bell-icon class="fea icon-sm fea-social"></bell-icon>
                    </a>
                  </li>
                </ul>
                <!--end icon-->
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>

    <section
      class="bg-half bg-light d-table w-100 mt-30"
      style="padding-top: 60px; padding-bottom: 60px"
      :style="'background: url(http://historic.job4u.pk/htmlcss/images/exposure.jpg) center center'"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h1
                class="heading-inverse align-center"
                style="
                  background: url('images/hr-inverse.png') center 48px no-repeat;
                "
              >
                Maximize your exposure
              </h1>

              <p style="color: white; font-size: 18px">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo,
                rhoncus ut, imperdiet a, venenatis vitae, justo. Lorem ipsum
                dolor sit amet, consectetuer adipiscing elit. Aenean commodo
                ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et
                magnis dis parturient montes, nascetur ridiculus mus.
              </p>
              <div class="row" style="justify-content: center">
                <div
                  class="buy-button"
                  style="
                    width: 24%;
                    display: flex;
                    justify-content: center;
                    margin-top: 3%;
                  "
                >
                  <a href="/" class="btn btn-default btn-lg outline-btn-inverse"
                    >Post a Job</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <div class="col-sm-12 col-md-12 col-lg-12 mt-20 mb-20">
      <h1 class="heading_featured align-center">Search CVs for free</h1>
      <h3 class="align-center">
        Access thousands of CVs through our CV Search Platform
      </h3>
      <br />
      <div class="align-center">
        <a
          href="#"
          class="btn btn-default btn-lg top-form-btn Upload_Your_CV"
          style="border-color: rgb(0, 86, 150)"
          ><span>Info &amp; Prices</span></a
        >
      </div>
    </div>
    <div class="detail">
      <div class="container more-detail row pt-20 pb-20" style="margin: 0 auto">
        <div class="col-sm-4">
          <div class="align-center">
            <h2 style="font-weight: 600">Direct employers</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
            <a href="#" class="link_clor_style"
              ><span
                >More Details &nbsp;<i
                  class="fa fa-angle-double-right"
                ></i></span
            ></a>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="align-center">
            <h2 style="font-weight: 600">Recruitment agencies</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
            <a href="#" class="link_clor_style"
              ><span
                >More Details &nbsp;<i
                  class="fa fa-angle-double-right"
                ></i></span
            ></a>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="align-center">
            <h2 style="font-weight: 600">Assisted recruitment</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
            <a href="#" class="link_clor_style"
              ><span
                >More Details &nbsp;<i
                  class="fa fa-angle-double-right"
                ></i></span
            ></a>
          </div>
        </div>
      </div>
    </div>
    <section
      class="bg-half bg-light d-table w-100"
      style="padding-top: 55px; padding-bottom: 55px"
      :style="'background: url(http://historic.job4u.pk/htmlcss/images/explore-more.png) center center'"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div
              class="page-next-level"
              style="display: flex; justify-content: space-around"
            >
              <div>
                <h1
                  class="title mt-4 mb-3"
                  style="color: white; font-weight: 700; letter-spacing: 0"
                >
                  We will help to find the Candidates that are right for you
                </h1>
                <h3 style="color: white">
                  There are hundreds of Job Seekers out there. We'll help you
                  find the best out of them.
                </h3>
              </div>
              <div>
                <div
                  class="buy-button"
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-top: 18%;
                  "
                >
                  <a href="/" class="btn btn-default btn-lg outline-btn-inverse"
                    >Post a Job</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
